import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/atlassian/pipelines/agent/build/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Card, CardWithHeader } from '../src';
import { Playground, Props } from 'docz';
import { ButtonPrimary, ButtonSecondary } from "@happeouikit/buttons";
import { PackageMetadata } from '@happeo/docs-tools';
import pkg from "../package.json";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "card"
    }}>{`Card`}</h1>
    <PackageMetadata pkg={pkg} mdxType="PackageMetadata" />
    <h3 {...{
      "id": "default"
    }}>{`Default`}</h3>
    <Playground __position={1} __code={'<Card>\n  <div>\n    <p>\n      Maecenas faucibus mollis interdum. Vivamus sagittis lacus vel augue\n      laoreet rutrum faucibus dolor auctor. Donec sed odio dui.\n    </p>\n    <ButtonPrimary text=\"Do action!\" />\n    <ButtonSecondary text=\"Cancel Action!\" />\n  </div>\n</Card>'} __scope={{
      props,
      DefaultLayout,
      Card,
      CardWithHeader,
      Playground,
      Props,
      ButtonPrimary,
      ButtonSecondary,
      PackageMetadata,
      pkg,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
      <Card mdxType="Card">
  <div>
  <p>Maecenas faucibus mollis interdum. Vivamus sagittis lacus vel
  augue laoreet rutrum faucibus dolor auctor. Donec sed odio dui.</p>
    <ButtonPrimary text="Do action!" mdxType="ButtonPrimary" />
    <ButtonSecondary text="Cancel Action!" mdxType="ButtonSecondary" />
        </div>
      </Card>
    </Playground>
    <Playground __position={2} __code={'<Card fullWidth={false}>\n  <div>\n    <p>This is now auto width</p>\n  </div>\n</Card>'} __scope={{
      props,
      DefaultLayout,
      Card,
      CardWithHeader,
      Playground,
      Props,
      ButtonPrimary,
      ButtonSecondary,
      PackageMetadata,
      pkg,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
      <Card fullWidth={false} mdxType="Card">
  <div>
    <p>This is now auto width</p>
  </div>
      </Card>
    </Playground>
    <h2 {...{
      "id": "props"
    }}>{`Props`}</h2>
    <Props of={Card} mdxType="Props" />
    <h1 {...{
      "id": "cardwithheader"
    }}>{`CardWithHeader`}</h1>
    <p>{`This is a card that has a header.`}</p>
    <CardWithHeader header="Give the card header" mdxType="CardWithHeader">
      <p>Content goes in here</p>
    </CardWithHeader>
    <h2 {...{
      "id": "usage-examples"
    }}>{`Usage examples`}</h2>
    <Playground __position={5} __code={'<CardWithHeader header=\"Give the card header\">\n  <div>\n    <p>\n      Maecenas faucibus mollis interdum. Vivamus sagittis lacus vel augue\n      laoreet rutrum faucibus dolor auctor. Donec sed odio dui.\n    </p>\n    <ButtonPrimary text=\"Do action!\" />\n    <ButtonSecondary text=\"Cancel Action!\" />\n  </div>\n</CardWithHeader>'} __scope={{
      props,
      DefaultLayout,
      Card,
      CardWithHeader,
      Playground,
      Props,
      ButtonPrimary,
      ButtonSecondary,
      PackageMetadata,
      pkg,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
      <CardWithHeader header="Give the card header" mdxType="CardWithHeader">
    <div>
        <p>Maecenas faucibus mollis interdum.
        Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.
        Donec sed odio dui.
        </p>
        <ButtonPrimary text="Do action!" mdxType="ButtonPrimary" />
        <ButtonSecondary text="Cancel Action!" mdxType="ButtonSecondary" />
    </div>
      </CardWithHeader>
    </Playground>
    <h2 {...{
      "id": "props-1"
    }}>{`Props`}</h2>
    <Props of={CardWithHeader} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      